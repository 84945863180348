<template>
  <section class="min-w1600">
    <!-- <div class="searchBar line"></div> -->
    <div class="searchwrap">
      <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('searchArea.date') }}</span> :</div>
           <date-selector :class="'searchbar-content'"
                          @setStartDate="setStartDate"
                          @setEndDate="setEndDate"
                          :startDefault="startDefault"
                          :endDefault="endDefault"
                          :configs="dateConfigs"
                          :isOldYn="true"
                          @setOldYn="setOldYn" :oldYn="reqData.oldYn" />
         </div>
         <!-- <div class="box-ui-select searchbar-content">
           <div class="title">
             <span>{{ $t('common.sort') }}</span> :
           </div>
           <select v-model="order">
             <option :value="''">번호</option>
             <option :value="'betAmt DESC'">베팅금 많은 순</option>
             <option :value="'betAmt ASC'">베팅금 적은 순</option>
             <option :value="'betWinAmt desc'">당첨금 많은 순</option>
             <option :value="'betWinAmt asc'">당첨금 적은 순</option>
           </select>
         </div> -->
         <div class="box-ui-select searchbar-content">
           <div class="title">
             <i class="fas fa-cog"></i>
             <span>{{ $t('searchArea.memberID') }}</span> :
           </div>
           <input type="text" class="mr-5" v-model="reqData.memId" />
         </div>
         <button class="btn-search" type="button" @click="pageSeach">
           <i class="fas fa-search"></i>
         </button>
      </div>
    </div>
    <div class="tab-wrap">
      <!-- <select v-model="currentPage">
        <option :value="'all'">{{ $t('common.total') }}</option>
        <template v-for="item in tabList" :key="item.code">
          <option :value="item.code">{{ item.codeName }}</option>
        </template>
      </select> -->
    </div>
    <div class="main-contents-wrapper">
      <div class="table-wrapper">
        <table class="mainTable">
          <table-head :headInfo="headInfo" />
          <tbody>
            <!--
              <td>123</td>
              <td>testID</td>
              <td>testBetID</td>
              <td>testTransactionID</td>
              <td>game</td>
              <td>game type</td>
              <td>Type</td>
              <td>150,000</td>
              <td>130,000</td>
              <td>Y</td>
              <td>2023-07-07 15:27</td>
            -->

            <td colspan="15">{{ $t('txt.noData') }}</td>
          </tbody>
        </table>
      </div>
    </div>
    <pagination v-if="betList.length !== 0" @goPage="goPage" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />
  </section>

  <bet-detail v-if="isDetailOpen" :meta="detailMeta" @close="betDetailClose"></bet-detail>

</template>

<script>
import SearchFilter from '@/components/common/SearchFilter'
import DateSelector from '@/components/common/DateSelector'
import TableHead from '@/components/main/table/Head.vue'
import UiCheck from '@/components/ui/UiCheckSet'
import subTitle from '@/components/main/PageSubTitle'
import { betList, getCode } from '@/api/member.js'
import { GAME_INFO_LIST, GAME_CODE_NAME } from '@/libs/constants'
import { replaceDateT, numberWithCommas, getDateStr } from '@/libs/utils.js'
import Pagination from '@/components/common/Pagination'
import BetDetail from '@/views/member/bet/pages/betDetail.vue'

export default {
  name: 'betTransaction',
  components: {
    BetDetail,
    DateSelector,
    TableHead,
    Pagination
  },
  data: function () {
    return {
      tableData: {},
      headInfo: {
        fstColumn: false,
        dataList: ['index', 'siteID', 'memId', 'transID', 'betID', 'type', 'category', 'productName', 'gameName', 'beforeAmt', 'transAmt', 'afterAmt', 'isCancel', 'isBonus','transactiontime']
      },
      reqData: {
        page: 1,
        count_per_list: '30',
        betType: 'kgon',
        gameType: '1',
        memId: '',
        oldYn: 'N'
      },
      order: '',
      pageInfo: {},
      betList: [],
      codeList: [],
      convertData: {},
      gameType: {},
      tabList: {},
      currentPage: '',
      dateConfigs: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i'
      },
      startDefault: '',
      endDefault: '',
      currentPageName: '',
      isDetailOpen: false,
      detailMeta: null
    }
  },
  methods: {
    async betDetailOpen (item) {
      this.detailMeta = item
      this.isDetailOpen = true
    },
    async betDetailClose () {
      this.detailMeta = null
      this.isDetailOpen = false
    },
    async setTabList () {
      const group = this.$route.meta.group
      // console.log(group);
      const gameList = await this.$store.getters.gameList
      // console.log(gameList);
      if (gameList.length) {
        const gameObj = gameList.find(obj => {
          return obj.code === group
        })
        this.tabList = gameObj.child
      }
    },
    async setGameType () {
      const pathStr = this.$route.path.split('/')
      this.reqData.vendorCode = pathStr[4]
      this.currentPage = pathStr[4]
    },
    pageSeach () {
      this.lastDataRefector(this.currentPage, 1)
    },
    setTitleName () {
      const locale = this.$i18n.locale
      this.tabList.forEach(item => {
        if (this.currentPage.toString() === item.code) {
          this.currentPageName = locale === 'ko' ? item.codeName : item['codeName' + locale.toUpperCase()]
        } else {
          if (this.currentPage === 'all') {
            this.currentPageName = locale === 'ko' ? '전체보기' : 'All View'
          }
        }
      })

      this.emitter.emit('gameTitle', this.currentPageName)
    },
    setOldYn (data) {
      this.reqData.oldYn = data
    },
    setStartDate (date) {
      // console.log(date);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(0, 0, 0)
      } else {
        _date = date[0]
      }
      this.startDefault = _date
      this.reqData.startDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    setEndDate (date) {
      // console.log(date[0]);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(23, 59, 59)
      } else {
        _date = date[0]
      }
      this.endDefault = _date
      this.reqData.endDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    async getBetList (code, pageNum) {
      if (pageNum) {
        this.reqData.page = pageNum
      }
      this.reqData.gameCategory = 'casino'
      if (code === 'all') {
        code = ''
      }
      this.reqData.vendorCode = code
      const data = this.reqData
      if (this.order !== '') {
        const tempOrder = this.order.split(' ')
        const orderColumn = tempOrder[0]
        const orderType = tempOrder[1]

        data.orderColumn = orderColumn
        data.orderType = orderType
      } else {
        data.orderColumn = ''
        data.orderType = ''
      }
      console.log(data)
      return await betList(data)
    },
    async getGameCode (reqData) {
      const data = reqData
      return await getCode(data)
    },
    gameCodeDetacher (list) {
      const set = new Set(list)
      const uniqueType = [...set]
      return uniqueType
    },
    async gameCodeConverter (list) {
      const convertList = {}
      for (const item of list) {
        convertList[item] = []
        const data = await this.getGameCode(item)
        convertList[item] = data
      }
      return convertList
    },
    goPage (pageNum) {
      const code = this.reqData.vendorCode
      this.lastDataRefector(code, pageNum)
    },
    async lastDataRefector (code, pageNum) {
      this.emitter.emit('Loading', true)
      window.scrollTo(0, 0)
      if (!pageNum) {
        pageNum = 1
      }
      const listRes = await this.getBetList(code, pageNum)
      const pageInfo = listRes.data.pageInfo
      if (pageInfo) {
        this.pageInfo = pageInfo
      }
      const list = listRes.data.list
      const preBetType = []
      list.forEach(item => {
        const status = item.betStatus

        preBetType.push(item.betType)

        item.wlAmt = '0'
        const _wlAmt = item.betWinAmt - item.betAmt
        const type = item.type
        if (type === 'win') {
          item.result = '승리'
          item.wl = true
        } else if (type === 'lose') {
          item.result = '패배'
          item.wl = false
        } else if (type === 'draw') {
          item.result = '무'
          item.wl = false
        } else if (type === 'wait') {
          item.result = '대기'
          item.wl = false
        } else {
          item.result = '취소'
          item.wl = false
        }
        item.wlAmt = numberWithCommas(_wlAmt)
      })
      const gTypes = this.gameCodeDetacher(preBetType)
      this.convertData = await this.gameCodeConverter(gTypes)
      // console.log(this.convertData)
      list.forEach(item => {
        const gameCode = this.convertData[item.betType]
        for (const _item of gameCode) {
          if (_item.code === item.gameType) {
            item.provider = _item.codeName
            item.gameType = _item.groupCode
          }
        }
        item.gameType = GAME_CODE_NAME[item.gameType]
      })
      this.betList = list
      this.emitter.emit('Loading', false)
    }
  },
  watch: {
    gameList: {
      handler (value) {
        this.setTabList()
      }
    },
    tabList: {
      handler (value) {
        if (this.tabList.length > 0 && this.currentPage) {
          this.setTitleName()
        }
      }
    },
    currentPage () {
      this.lastDataRefector(this.currentPage, 1)
    }
  },
  computed: {
    betDetail () {
      return betDetail
    },
    gameList () {
      return this.$store.getters.gameList
    }
  },
  async created () {
    this.emitter.emit('Loading', true)
    if (this.$route.query.id) {
      this.reqData.memId = this.$route.query.id
    }
    this.setStartDate()
    this.setEndDate()
    await this.setTabList()
    await this.setGameType()
    await this.lastDataRefector(this.reqData.vendorCode, 1)
    this.emitter.emit('Loading', false)
  }
}
</script>

<style scoped>
.main-sub-data-item {
  flex: 1 1 0;
  margin-right: 10px;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 3px 3px 12px 0px #d1d1d1;
  display: flex;
}
.main-sub-data-item > div {
  padding: 8px 0;
  text-align: center;
  box-sizing: border-box;
}
.sub-data-item-title {
  background: #ab2926;
  color: #fff;
  font-weight: 700;
  width: 35%;
}
.sub-data-item-content {
  font-weight: 700;
  text-align: right;
  width: 65%;
}
.main-sub-data-item:last-child {
  margin-right: 0;
}

.btn-total-data {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  background: #2f4858;
  width: fit-content;
  border-radius: 3px;
  border: 2px solid #2f4858;
}
.btn-total-data:hover {
  color: #2f4858;
  background: #f1f1e6;
}
.btn-total-data > i {
  margin-right: 5px;
}
.btn-total-data > span {
  font-weight: 700;
  letter-spacing: 2px;
}
</style>
